import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import FormHint from "../components/FormHint";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Drag } from "grommet-icons/es6";
import PageWrapper from "../components/PageWrapper";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ROUND = gql`
  query GetRound($quizId: ID!, $roundNumber: Int!) {
    round: getRound(quizId: $quizId, roundNumber: $roundNumber) {
      id
      number
      title
      questions {
        id
        number
        text
        answer
      }
      quiz {
        started
      }
    }
  }
`;

const SAVE_ROUND = gql`
  mutation SaveRound($quizId: ID!, $title: String!, $roundNumber: Int) {
    saveRound(quizId: $quizId, roundNumber: $roundNumber, title: $title) {
      round {
        id
        number
        title
      }
    }
  }
`;

const REORDER_QUESTIONS = gql`
  mutation ReorderQuestions(
    $quizId: ID!
    $roundNumber: Int!
    $questionId: ID!
    $newNumber: Int!
  ) {
    reorderQuestions(
      quizId: $quizId
      roundNumber: $roundNumber
      questionId: $questionId
      newNumber: $newNumber
    ) {
      round {
        id
        questions {
          id
          number
          text
        }
      }
    }
  }
`;

const DELETE_ROUND = gql`
  mutation DeleteRound($quizId: ID!, $roundNumber: Int!) {
    deleteRound(quizId: $quizId, roundNumber: $roundNumber) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuizStartedMessage = styled.div`
  font-size: 18px;
  color: red;
  margin-bottom: 20px;
`;

const QuestionHeading = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

const QuestionListing = styled.div`
  margin-bottom: 10px;
  svg {
    opacity: 0;
  }

  ${({ disabled }) =>
    !disabled &&
    `
    :hover svg {
      opacity: 1;
    }
  `}
`;

const DragWrapper = styled.span`
  padding: 15px 5px;

  :hover {
    cursor: grab;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const RoundEditor = ({
  eventId,
  quizId,
  roundNumber,
  questions,
  originalValues,
  isStarted,
  onReorderQuestions,
  onDelete,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    submitForm,
    isSubmitting,
  } = useFormikContext();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () => values.title !== originalValues.title;

  const handleDragEnd = async (result) => {
    const questionId = result.draggableId;
    const newNumber = result.destination.index + 1;

    await onReorderQuestions(questionId, newNumber);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {!roundNumber ? "Add a Round" : "Edit Round"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          {isStarted && (
            <QuizStartedMessage>
              Round is not editable since quiz has already started.
            </QuizStartedMessage>
          )}

          <FormField>
            <Label htmlFor="title">Title</Label>
            <Input
              type="text"
              name="title"
              autoComplete="off"
              disabled={isStarted}
              value={values.title}
              onChange={handleChange}
            />
            {errors.title && touched.title && (
              <FormError>{errors.title}</FormError>
            )}
          </FormField>
          <QuestionHeading>Questions</QuestionHeading>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {questions.map((question, index) => (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                      isDragDisabled={isStarted}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <QuestionListing disabled={isStarted}>
                            <DragWrapper>
                              <Drag size="small" />
                            </DragWrapper>

                            <Link
                              key={question.id}
                              to={`/create-edit-question?eventId=${eventId}&quizId=${quizId}&roundNumber=${roundNumber}&questionNumber=${question.number}`}
                            >
                              Question {question.number}: {question.text}
                            </Link>
                          </QuestionListing>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <ButtonWrapper>
            <Link
              to={`/create-edit-question?eventId=${eventId}&quizId=${quizId}&roundNumber=${roundNumber}`}
            >
              <Button disabled={!roundNumber || isStarted}>
                {questions.length === 0
                  ? "Add First Question"
                  : "Add a Question"}
              </Button>
            </Link>
            {!roundNumber && (
              <FormHint>Save round in order to add questions</FormHint>
            )}
          </ButtonWrapper>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save Round"}
        </Button>
        <Link
          to={
            eventId
              ? `/create-edit-event?eventId=${eventId}`
              : `/create-edit-quiz?quizId=${quizId}`
          }
        >
          <Button size="large">Close</Button>
        </Link>
        <DangerButton
          size="large"
          disabled={!roundNumber || isStarted}
          onClick={handleDeleteClick}
        >
          Delete Round
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Round"
          text="Delete this round and all the related questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditRound({ location }) {
  const { quizId, roundNumber, eventId } = queryString.parse(location.search);

  const { data, refetch } = useQuery(GET_ROUND, {
    variables: {
      quizId: quizId,
      roundNumber: roundNumber,
    },
    skip: !roundNumber,
  });

  const [saveRound] = useMutation(SAVE_ROUND);
  const [reorderQuestions] = useMutation(REORDER_QUESTIONS);
  const [deleteRound] = useMutation(DELETE_ROUND);

  if (roundNumber && !data) {
    return null;
  }

  const originalValues = (data && data.round) || {};

  const initialValues = {
    title: data && data.round && data.round.title,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "You must enter a title";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    const result = await saveRound({
      variables: {
        quizId: quizId,
        roundNumber: roundNumber,
        title: values.title,
      },
    });
    const newRoundNumber = result.data.saveRound.round.number;
    await navigate(
      `/create-edit-round?quizId=${quizId}&roundNumber=${newRoundNumber}&eventId=${eventId}`
    );
    await refetch({ quizId, roundNumber: newRoundNumber });
  };

  const handleReorderQuestions = async (questionId, newNumber) => {
    await reorderQuestions({
      variables: {
        quizId,
        roundNumber,
        questionId,
        newNumber,
      },
    });
    await refetch();
  };

  const handleDelete = async () => {
    await deleteRound({
      variables: {
        quizId: quizId,
        roundNumber: roundNumber,
      },
    });
    if (eventId) {
      await navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      await navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <RoundEditor
          eventId={eventId}
          quizId={quizId}
          roundNumber={roundNumber}
          questions={(data && data.round && data.round.questions) || []}
          originalValues={originalValues}
          isStarted={
            data && data.round && data.round.quiz && data.round.quiz.started
          }
          onReorderQuestions={handleReorderQuestions}
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
